@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.main_bg{
    background: linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.card_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5;
    color: white !important;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%);
}
.main_container{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-radius: 10;
}
.normal_shadow{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; 
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  border-radius: 50%;
}

.login-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  border-radius: 5%;
}

#__react-alert__ div div div{
  max-width: auto !important;
  word-break: break-word !important;
}

/* .content_bg {
  background: "#ffffff";
  box-shadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
}

borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" */

/* className='thead-light' */


/* table */
/* style={{
  borderRadius: 5, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
  border: "50px solid linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"

}} */

.tail-datetime-calendar {
    /* width: 245px; */
    width: 945px;
    height: auto;
    /* margin: 15px; */
    margin-left: 10px;
    margin-right: 10px;
    padding: 0;
    display: block;
    overflow: hidden;
    /* position: absolute; */
    border-collapse: separate;
    font-family: "Open Sans", Calibri, Arial, sans-serif;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }
  .tail-datetime-calendar:after {
    clear: both;
    content: "";
    display: block;
    font-size: 0;
    visibility: hidden;
  }
  .tail-datetime-calendar.calendar-static {
    margin-left: auto;
    margin-right: auto;
  }
  .tail-datetime-calendar .calendar-navi {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    /* background-color: #cd283c; */
    border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-bottom: 2px solid #12171d;
    background: #51445f;
  }
  .tail-datetime-calendar .calendar-navi span {
    cursor: pointer;
    color: #fff;
    margin: 0;
    padding: 0;
    display: table-cell;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-position: center center;
  }
  .tail-datetime-calendar .calendar-navi span:first-child,
  .tail-datetime-calendar .calendar-navi span:last-child {
    width: 35px;
    padding: 0 0 5px 0;
    font-size: 22px;
  }
  .tail-datetime-calendar .calendar-navi span:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
  .tail-datetime-calendar .calendar-navi span.button-prev {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
      9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
      TYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+");
  }
  .tail-datetime-calendar .calendar-navi span.button-next {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
      9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
      TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=");
  }
  .tail-datetime-calendar .calendar-navi span.button-check {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
      9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
      SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
  }
  .tail-datetime-calendar .calendar-navi span.button-close {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
      9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
      SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\
      NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
  }
  .tail-datetime-calendar .calendar-date {
    margin: 0;
    padding: 0;
    display: block;
  }
  .tail-datetime-calendar .calendar-date table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
    border-collapse: collapse;
  }
  .tail-datetime-calendar .calendar-date table thead tr > *,
  .tail-datetime-calendar .calendar-date table tbody tr > * {
    color: #ffffff;
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    line-height: 35px;
    border: 0;
    /* background-color: #223344; */
    background-color:#51445f
  }
  .tail-datetime-calendar .calendar-date table tbody tr > * {
    color: #334455;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    background-color: #ffffff;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > * > span {
    color: inherit;
    z-index: 10;
    position: relative;
  }
  /* Select a Day */
  /* .tail-datetime-calendar .calendar-date table tbody tr > *::after {
    top: 18px;
    left: 53px;
    width: 29px;
    height: 29px;
    content: "";
    display: block;
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    -webkit-border-radius: 50%;
  } */
  .tail-datetime-calendar .calendar-date table tbody tr > *.pad {
    padding: 8px;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.pad2 {
    padding-left: 9px !important;
    padding-right: 10px !important;
  }
 
  .tail-datetime-calendar .calendar-date table tbody tr td > *.present_days {
    border-bottom: 3px solid #18b82d;
    background: #18b82d;
    padding: 2px 6px;
    color: #fff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.absent_days {
    border-bottom: 3px solid red;
    background: red;
    padding: 2px 6px;
    color: #fff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.holiday_days {
    border-bottom: 3px solid #0ec9d2;
    background: #0ec9d2;
    padding: 2px 6px;
    color: #fff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.leave_days {
    border-bottom: 3px solid #FFA500;
    background: #FFA500;
    padding: 2px 6px;
    color: #fff;
  }


  .tail-datetime-calendar .calendar-date table tbody tr > *.today {
    color: #3296c8;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.today:after {
    border-color: #3296c8;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *:hover {
    color: #cd283c;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *:hover:after {
    border-color: #cd283c;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.empty,
  .tail-datetime-calendar .calendar-date table tbody tr > *.disable {
    color: #8899aa;
    cursor: not-allowed;
    background-color: #efefef;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.empty:hover:after,
  .tail-datetime-calendar .calendar-date table tbody tr > *.disable:hover:after {
    content: "";
    display: none;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.current span {
    color: #fff;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.current:after {
    border-color: #3296c8;
    background-color: #3296c8;
  }
  /* Select A Month */
  .tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * {
    width: 81.66666666666667px;
    padding: 5px;
    line-height: 25px;
  }
  .tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * span {
    display: block;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }
  .tail-datetime-calendar
    .calendar-date
    table.calendar-month
    tbody
    tr
    > *:hover
    span {
    border-color: #d0d0d0;
    box-shadow: 0 1px 0 0 #efefef;
    -webkit-box-shadow: 0 1px 0 0 #efefef;
  }
  /* Select a Time */
  .tail-datetime-calendar .calendar-time {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    text-align: center;
    background-color: #ffffff;
    border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
  }
  .tail-datetime-calendar .calendar-date + .calendar-time {
    border-top: 1px solid #d0d0d0;
    background-color: #f8f8f8;
  }
  .tail-datetime-calendar .calendar-time .calendar-field {
    width: 33.3333333333%;
    padding: 10px 0;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: top;
  }
  .tail-datetime-calendar .calendar-time .calendar-field:first-child {
    text-align: right;
  }
  .tail-datetime-calendar .calendar-time .calendar-field:first-child:after {
    top: 12px;
    right: -10px;
    content: ":";
    position: absolute;
  }
  .tail-datetime-calendar .calendar-time .calendar-field:last-child {
    text-align: left;
  }
  .tail-datetime-calendar .calendar-time .calendar-field:last-child:after {
    top: 12px;
    left: -10px;
    content: ":";
    position: absolute;
  }
  .tail-datetime-calendar .calendar-time .calendar-field input[type="number"] {
    width: 100%;
    max-width: 50px;
    margin: 0;
    padding: 3px 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }
  .tail-datetime-calendar
    .calendar-time
    .calendar-field
    input[type="number"]:hover {
    border-color: #a0a0a0;
  }
  .tail-datetime-calendar
    .calendar-time
    .calendar-field
    input[type="number"]:focus {
    border-color: #3296c8;
  }
  .tail-datetime-calendar .calendar-time .calendar-field label {
    color: #778899;
    margin: 5px 0 0 0;
    padding: 0;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  /* @end tail.DateTime */
  
  .sqr {
    float: left;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
  }
  
  .precent {
    background: #18b82d;
  }
  
  .holiday {
    background: #0ec9d2;
  }
  
  .weekly_off {
    background: red;
  }
  .leave{
    background: #FFA500;
  }
.f_left{
    float: left; 
}

.innerbox{
    min-height: 60px; padding:10px 0; background-color: #e9f1fb; 
    border-top:1px dashed #000; 
    border-bottom:1px dashed #000;
}

.mtop50{
    margin-top:50px;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #dce4af; 
    opacity: 1; 
  }

  .form-control {
    
    padding: .275rem .55rem !important;
    font-size: 0.8rem  !important;
    

  } 

  label{
      color:#293440 !important;
      margin-top: 3px; margin-bottom: 0.3rem;
  }

  .bbox{
     border: 1px dashed #ccc; 
     background: #e4eff1;  
  }

  .hide{
      display: none;
  }

  .show{
    display: '';
   }                                            

   .bgcls{
       background-color: #8c8792 !important;
       border-bottom: #8c8792 !important;
   }
   .p_2{
       padding:3px;
   }
   .w_20{
       width: 180px;
   }
   .w_5{
       width: 20px;
   }
   .prof{
       width: 50px;
       height: 50px;
       border-radius: 50%;
    -webkit-border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
   }

   .compbtn {
    border: none;
    /* transition: 0.5s; */
  }
  .compbtn:hover {border:2px solid #0084ff;}

  .compbr{
    border:2px solid #0084ff;
  }

  /* .lodemodel{
    border:unset !important;
    background-color:unset !important;
  } */

  
  .tail-datetime-calendar .calendar-date table tbody tr > * > span{
      z-index: unset !important;
  }

  .wrap{
    word-wrap: 'break-word';
  }

  .homecmp-container .webcam-container
{
display: flex;
flex-direction: column;
text-align: center;
padding:10px 0;
}
.homecmp-container .webcam-container .webcam-btn
{
margin-top: 10px;
width:30%;
}

.sticky_header{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
}
.p_20{
    padding: 20px;
}
.pb_10{
    padding-bottom: 10px;
}

.pb_13{
    padding-bottom: 13px !important;
}
.pb_14{
    padding-bottom: 14px !important;
}
.pb_15{
    padding-bottom: 15px !important;
    padding-top: 5px !important;
}
.pb_20{
    padding-bottom: 20px !important;
}

.pt_15{
    padding-top: 15px;
}
.pt_20{
    padding-top: 25px;
}
.pt_25{
    padding-top: 40px;
}

.p_t_b{
    padding: 0px 15px;
}
.ptb{
    padding: 0px 27px 20px;
}

.p_l_35{
    padding-left: 35px;
}

.p_l_t{
    padding-left: 55px;
    padding-top: 15px;
}

.p_l_t_30{
    padding-left: 30px;padding-top: 10px;
}

.border{
    border:1px solid #a3a3a3;padding: 10px;
    padding: 18px;
}

.required{
    color:red;
}

.submit_btn{
    padding-left: 30%;
    padding-top: 50px;
}

  .model_head{
    background-color: aliceblue;
  }
  .text_center{
    width: 20%;
    margin-left: 35%;
    margin-right: 35%;
  }

  .ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
  .flex{
      display: flex !important;
  }

  
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #47ccde ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #47ccde ;
    border-color: #47ccde;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #47ccde
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }

 

 .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
  }
/*just bg and body style*/
.container{
    background-color:#51445f;
    padding-bottom:20px;
    margin-top:10px;
    border-radius:5px;
    /* padding-top: 5px; */
    }
    .center{
    text-align:center;  
    }
    #top{
    margin-top:20px;  
    }
    .btn-container{
    background:#fff;
    border-radius:5px;
    padding-bottom:50px;
    margin-bottom:20px;
    padding-top: 5px;
    }
    .white{
    color:white;
    }
    .imgupload{
    color:#1E2832;
    padding-top:40px;
    font-size:7em;
    }
    #namefile{
    color:black;
    }
    h4>strong{
    color:#ff3f3f
    }
    /* .btn-primary{
    border-color: #ff3f3f !important;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #ff3f3f !important;
    border-color: #ff3f3f !important;
    } */
    
    /*these two are set to not display at start*/
    .imgupload.ok{
    display:none;
    color:green;
    }
    .imgupload.stop{
    display:none;
    color:red;
    }
    
    
    /*this sets the actual file input to overlay our button*/ 
    #fileup{
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    width:200px;
    cursor: pointer;
    position:absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    height: 50px;
    }
    
    /*switch between input and not active input*/
    #submitbtn{
      padding:5px 50px;
      display:none;
    }
    #fakebtn{
      padding:5px 40px;
    }
    
    
    /*www.emilianocostanzo.com*/
    #sign{
        color:#1E2832;
        position:fixed;
        right:10px;
        bottom:10px;
        text-shadow:0px 0px 0px #1E2832;
        transition:all.3s;
    }
    #sign:hover{
        color:#1E2832;
        text-shadow:0px 0px 5px #1E2832;
    }

    /* .title {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #999;
        text-align: center;
    }
    
    h1 {
        font-size: 16px;
        font-weight: 300;
        color: #666;
    }
     */
    .dropzone {
        /* width: 100px;
        height: 80px; */
        border: 1px dashed #999;
        border-radius: 3px;
        text-align: center;
        margin: 30px;
    padding-top: 10px;
    }
    
    .upload-icon {
        margin: 25px 2px 2px 2px;
    }
    
    .upload-input {
        position: relative;
        top: -62px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .reactEasyCrop_Container{
        margin: 0px 7px 0px 20px;
        border-radius: 6%;
    }
.main_bg{
    background: linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.card_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5;
    color: white !important;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%);
}
.main_container{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-radius: 10;
}
.normal_shadow{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; 
}

.clr{
    color: #ffa500;
}

.p_t_10{
    padding-top:1px;
}

.p_t_5{
    padding-top:1px;
    padding-bottom:1px;
}
.p_t1_5{
    padding-top:5px;
}
.p_t_30{
    padding-top: 30px;
}

.p_t_45_l_35{
    padding-top: 45px;
    padding-left: 35%;
}
.head{
    background-color: #ffa500;
    color:white;
    margin-left: 10px;
    margin-right: 10px;
}

.head1{
    color:#ffff !important;padding: 10px;margin-bottom:unset !important;
}

.text_align{
    text-align: right;
}
.text_align_center{
  text-align: center;
}
.p_t_50{
    padding-top: 50px;
}

.p_t1_8{
    padding-top:8px;
}

.hd{
    color:#000 !important;padding: 10px;margin-bottom:unset !important;
}

.p_t_15{
    padding-top: 15px;
}

.hd2{
    color:#9458ca;font-weight: bold 
}

.hd3{
    background-color: #ffa500;color:white;margin-left: 10px;margin-right: 10px;
}

.hd4{
    background-color: #64ab6c;color:white;margin-left: 10px;margin-right: 10px;
}

.hd5{
    background-color: #d375df;;color:white;margin-left: 10px;margin-right: 10px;
}

.p_t_20{
    padding-left: 20px;padding: 5px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #deb1e9 !important;
  }
  .form-control {
    border-bottom: 1px dotted !important;
    background:unset !important;
  }
  .p4{
      padding-right: 35px !important;
      padding-left: 35px !important;
  }
  .pb{
    padding-bottom: 40px !important;
  }
  .iq-footer {
        margin-left: unset !important;
  }

  .p_r{
    padding-right: 1px !important;
  }
  .p_r_0{
    padding-right: 0px!important;
  }

  .bk{
    background-color: #f7e2e9;
  }
  .bk1{
    background-color: antiquewhite;
  }

  .bk2{
    background-color: aliceblue;
  }

  .bk3{
    background-color: #cce9cc;
  }
  .text_center{
    text-align: center;
  }

  .text_end{
    text-align: end;
  }

  .p_l_1{
    padding-left: 1px !important;
  }

  .p_l_0{
    padding-left: 1px !important;
  }

  .border_0{
    border: 0px solid #ced4da !important;
    border-bottom: 1px dotted !important;
  }





  .databox-container {
    text-align: center;
    font-size: 0
  }
  .databox {
    display: inline-block;
    width: 100%;
    height: 65px;
    padding: 0;
    font-size: 0;
    margin-bottom: 30px;
    vertical-align: top;
    min-width: 130px
  }
  .databox .databox-icon {
    margin: 0;
    display: inline-block;
    width: 100%;
    text-align: center
  }
  .databox .databox-icon>.fa, .databox .databox-icon .typcn, .databox .databox-icon .glyphicon, .databox .databox-icon .wi {
    display: inline-block;
    margin: 0;
    text-align: center;
    position: relative
  }
  .databox .databox-icon>.fa:before, .databox .databox-icon .typcn:before, .databox .databox-icon .glyphicon:before, .databox .databox-icon .wi:before {
    font-size: 40px;
    display: block;
    text-align: center
  }
  .databox .databox-sparkline {
    padding-top: 0;
    margin: 0 auto;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    text-align: center;
    font-size: 24px
  }
  .databox .databox-piechart {
    padding-top: 0;
    margin: 0 auto;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 300
  }
  .databox .databox-header {
    display: block;
    font-size: 18px;
    font-family: 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-weight: 400;
    margin: 8px 5px;
    position: relative
  }
  .databox .databox-header i {
    margin-right: 5px
  }
  .databox .databox-number {
    display: block;
    font-size: 17px;
    line-height: 26px;
    margin: 2px;
    position: relative;
    font-family: 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-weight: 700
  }
  .databox .databox-number i {
    margin-right: 5px
  }
  .databox .databox-number.number-xs {
    font-size: 11px
  }
  .databox .databox-number.number-sm {
    font-size: 14px
  }
  .databox .databox-number.number-lg {
    font-size: 20px
  }
  .databox .databox-number.number-xlg {
    font-size: 23px
  }
  .databox .databox-number.number-xxlg {
    font-size: 30px
  }
  .databox .databox-title {
    display: block;
    font-size: 16px;
    font-weight: 300;
    margin: 2px;
    position: relative
  }
  .databox .databox-title i {
    margin-right: 5px;
    font-size: 13px
  }
  .databox .databox-text {
    display: block;
    font-size: 11px;
    margin: 4px 0 2px;
    position: relative
  }
  .databox .databox-text i {
    margin-right: 5px;
    font-size: 13px
  }
  .databox .databox-inlinetext {
    font-size: 11px;
    margin: 2px;
    position: relative
  }
  .databox .databox-inlinetext i {
    margin-right: 5px;
    font-size: 13px
  }
  .databox .databox-stat {
    display: inline-block;
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 2px 5px
  }
  .databox .databox-stat.stat-left {
    left: 7px;
    right: auto
  }
  .databox .databox-stat .stat-text {
    display: inline-block;
    font-size: 13px;
    padding-right: 5px;
    font-weight: 500
  }
  .databox .databox-stat .stat-icon {
    display: inline-block;
    font-size: 13px
  }
  .databox .databox-stat .stat-icon.icon-lg {
    font-size: 16px
  }
  .databox .databox-stat .stat-icon.icon-xlg {
    font-size: 18px
  }
  .databox .databox-stat.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
  }
  .databox .databox-stat[class*="bg-"] {
    color: #fff
  }
  .databox .databox-state {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 24px;
    width: 24px;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    background-color: #e5e5e5;
    text-align: center;
    box-shadow: 0 0 3px rgba(0,0,0,.2);
    border: 2px solid #fff
  }
  .databox .databox-state i {
    font-size: 13px;
    color: #fff;
    line-height: 20px
  }
  .databox .databox-row {
    width: 100%;
    margin: 0;
    position: relative
  }
  .databox .databox-row[class*="bg-"] {
    color: #fff
  }
  .databox .databox-row.bordered-bottom {
    border-bottom: 1px solid #fff
  }
  .databox .databox-row.bordered-top {
    border-top: 1px solid #fff
  }
  .databox .databox-row.bordered-left {
    border-left: 1px solid #fff
  }
  .databox .databox-row.bordered-right {
    border-right: 1px solid #fff
  }
  .databox .databox-row.bordered-thick {
    border-width: 2px
  }
  .databox .databox-row.row-1 {
    height: 8.33%
  }
  .databox .databox-row.row-2 {
    height: 16.66%
  }
  .databox .databox-row.row-3 {
    height: 25%
  }
  .databox .databox-row.row-4 {
    height: 33.33%
  }
  .databox .databox-row.row-5 {
    height: 41.66%
  }
  .databox .databox-row.row-6 {
    height: 50%
  }
  .databox .databox-row.row-7 {
    height: 58.33%
  }
  .databox .databox-row.row-8 {
    height: 66.66%
  }
  .databox .databox-row.row-9 {
    height: 75%
  }
  .databox .databox-row.row-10 {
    height: 83.33%
  }
  .databox .databox-row.row-11 {
    height: 91.66%
  }
  .databox .databox-row.row-12 {
    height: 100%
  }
  .databox .databox-cell {
    height: 100%;
    margin: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px
  }
  .databox .databox-cell[class*="bg-"] {
    color: #fff
  }
  .databox .databox-cell.bordered-bottom {
    border-bottom: 1px solid #fff
  }
  .databox .databox-cell.bordered-top {
    border-top: 1px solid #fff
  }
  .databox .databox-cell.bordered-left {
    border-left: 1px solid #fff
  }
  .databox .databox-cell.bordered-right {
    border-right: 1px solid #fff
  }
  .databox .databox-cell.bordered-thick {
    border-width: 2px
  }
  .databox .databox-cell.cell-1 {
    width: 8.33%
  }
  .databox .databox-cell.cell-2 {
    width: 16.66%
  }
  .databox .databox-cell.cell-3 {
    width: 25%
  }
  .databox .databox-cell.cell-4 {
    width: 33.33%
  }
  .databox .databox-cell.cell-5 {
    width: 41.66%
  }
  .databox .databox-cell.cell-6 {
    width: 50%
  }
  .databox .databox-cell.cell-7 {
    width: 58.33%
  }
  .databox .databox-cell.cell-8 {
    width: 66.66%
  }
  .databox .databox-cell.cell-9 {
    width: 75%
  }
  .databox .databox-cell.cell-10 {
    width: 83.33%
  }
  .databox .databox-cell.cell-11 {
    width: 91.66%
  }
  .databox .databox-cell.cell-12 {
    width: 100%
  }
  .databox.databox-inverted .databox-left {
    -lh-property: 0;
    width: calc(100% - 65px)
  }
  .databox.databox-inverted .databox-right {
    width: 65px
  }
  .databox.databox-transparent .databox-left {
    background-color: transparent!important
  }
  .databox.databox-transparent .databox-right {
    background-color: transparent!important
  }
  .databox .databox-left {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0;
    width: 65px;
    height: 65px;
    padding: 10px;
    color: #51445f
  }
  .databox .databox-left[class*="bg-"] {
    color: #fff
  }
  .databox .databox-right {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    -lh-property: 0;
    width: calc(100% - 65px);
    height: 65px;
    color: #555;
    padding: 5px 10px
  }
  .databox .databox-right.bordered {
    border-left: 1px solid #fff
  }
  .databox .databox-right.bordered-thick {
    border-left: 3px solid #fff
  }
  .databox .databox-right[class*="bg-"] {
    color: #fff
  }
  .databox.databox-shadowed {
    box-shadow: 0 0 3px rgba(0,0,0,.2)
  }
  .databox.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
  }
  .databox.radius-bordered .databox-left {
    -webkit-border-radius: 3px 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 3px;
    background-clip: padding-box
  }
  .databox.radius-bordered .databox-right {
    -webkit-border-radius: 0 3px 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 3px 0;
    background-clip: padding-box
  }
  .databox.radius-bordered .databox-right .databox-row:first-child {
    -webkit-border-radius: 0 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 0 0;
    background-clip: padding-box
  }
  .databox.radius-bordered .databox-right .databox-row:last-child {
    -webkit-border-radius: 0 0 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 0;
    background-clip: padding-box
  }
  .databox.databox-graded {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNkZGRkZGQiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: linear-gradient(to right, #ddd, #fff)
  }
  .databox.databox-halved .databox-left {
    width: 50%!important
  }
  .databox.databox-halved .databox-right {
    width: 50%!important
  }
  .databox.databox-lg {
    height: 80px
  }
  .databox.databox-lg .databox-icon>.fa:before, .databox.databox-lg .databox-icon .typcn:before, .databox.databox-lg .databox-icon .glyphicon:before, .databox.databox-lg .databox-icon .wi:before {
    font-size: 48px;
    padding: 6px 4px 7px
  }
  .databox.databox-lg .databox-sparkline {
    margin: 13px auto
  }
  .databox.databox-lg .databox-number {
    font-size: 20px;
    margin: 4px 0 6px
  }
  .databox.databox-lg .databox-left {
    width: 80px;
    height: 80px
  }
  .databox.databox-lg .databox-right {
    -lh-property: 0;
    width: calc(100% - 80px);
    height: 80px;
    padding: 10px 15px
  }
  .databox.databox-lg.databox-inverted .databox-left {
    -lh-property: 0;
    width: calc(100% - 80px)
  }
  .databox.databox-lg.databox-inverted .databox-right {
    width: 80px
  }
  .databox.databox-xlg {
    height: 170px
  }
  .databox.databox-xlg .databox-icon>.fa:before, .databox.databox-xlg .databox-icon .typcn:before, .databox.databox-xlg .databox-icon .glyphicon:before {
    padding: 6px 4px 7px
  }
  .databox.databox-xlg .databox-icon .wi:before {
    font-size: 55px!important
  }
  .databox.databox-xlg .databox-left {
    width: 170px;
    height: 170px
  }
  .databox.databox-xlg .databox-right {
    -lh-property: 0;
    width: calc(100% - 170px);
    height: 170px
  }
  .databox.databox-xlg.databox-inverted .databox-left {
    -lh-property: 0;
    width: calc(100% - 170px)
  }
  .databox.databox-xlg.databox-inverted .databox-right {
    width: 170px
  }
  .databox.databox-xxlg {
    height: 250px
  }
  .databox.databox-xxlg .databox-icon>.fa:before, .databox.databox-xxlg .databox-icon .typcn:before, .databox.databox-xxlg .databox-icon .glyphicon:before, .databox.databox-xxlg .databox-icon .wi:before {
    font-size: 200px;
    padding: 6px 4px 7px
  }
  .databox.databox-xxlg .databox-left {
    width: 250px;
    height: 250px
  }
  .databox.databox-xxlg .databox-right {
    -lh-property: 0;
    width: calc(100% - 250px);
    height: 250px
  }
  .databox.databox-xxlg.databox-inverted .databox-left {
    -lh-property: 0;
    width: calc(100% - 250px)
  }
  .databox.databox-xxlg.databox-inverted .databox-right {
    width: 250px
  }
  .databox.databox-vertical {
    width: 100%;
    height: 100px
  }
  .databox.databox-vertical .databox-top {
    position: relative;
    width: 100%;
    height: 50px;
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 5px;
    color: #51445f
  }
  .databox.databox-vertical .databox-top[class*="bg-"] {
    color: #fff
  }
  .databox.databox-vertical .databox-bottom {
    position: relative;
    width: 100%;
    height: 50px;
    display: inline-block;
    margin: 0;
    color: #555;
    padding: 5px 10px
  }
  .databox.databox-vertical .databox-bottom.bordered {
    border-top: 1px solid #fff
  }
  .databox.databox-vertical .databox-bottom.bordered-thick {
    border-top: 3px solid #fff
  }
  .databox.databox-vertical .databox-bottom[class*="bg-"] {
    color: #fff
  }
  .databox.databox-vertical .databox-icon>.fa:before, .databox.databox-vertical .databox-icon .typcn:before, .databox.databox-vertical .databox-icon .glyphicon:before, .databox.databox-vertical .databox-icon .wi:before {
    font-size: 38px
  }
  .databox.databox-vertical.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-top {
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-top .databox-row:first-child .databox-cell:first-child {
    -webkit-border-radius: 3px 0 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-top .databox-row:first-child .databox-cell:last-child {
    -webkit-border-radius: 0 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 0 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-top img {
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-bottom {
    -webkit-border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-bottom .databox-row:last-child .databox-cell:first-child {
    -webkit-border-radius: 0 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 0 3px;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-bottom .databox-row:last-child .databox-cell:last-child {
    -webkit-border-radius: 0 0 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 0;
    background-clip: padding-box
  }
  .databox.databox-vertical.radius-bordered .databox-bottom img {
    -webkit-border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box
  }
  .databox.databox-vertical.databox-graded {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNlNWU1ZTUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: linear-gradient(to bottom, #e5e5e5, #fff)
  }
  .databox.databox-vertical.databox-halved .databox-top {
    height: 50%
  }
  .databox.databox-vertical.databox-halved .databox-bottom {
    height: 50%
  }
  .databox.databox-vertical.databox-lg {
    height: 150px;
    width: 100%
  }
  .databox.databox-vertical.databox-lg .databox-number {
    font-size: 17px;
    margin: 2px
  }
  .databox.databox-vertical.databox-lg .databox-icon>.fa:before, .databox.databox-vertical.databox-lg .databox-icon .typcn:before, .databox.databox-vertical.databox-lg .databox-icon .glyphicon:before {
    font-size: 38px!important;
    padding: 0
  }
  .databox.databox-vertical.databox-lg .databox-icon .wi:before {
    font-size: 50px!important;
    padding: 0
  }
  .databox.databox-vertical.databox-lg .databox-top {
    height: 50px
  }
  .databox.databox-vertical.databox-lg .databox-bottom {
    height: 100px
  }
  .databox.databox-vertical.databox-lg.databox-inverted .databox-icon>.fa:before, .databox.databox-vertical.databox-lg.databox-inverted .databox-icon .typcn:before, .databox.databox-vertical.databox-lg.databox-inverted .databox-icon .glyphicon:before, .databox.databox-vertical.databox-lg.databox-inverted .databox-icon .wi:before {
    font-size: 85px;
    padding: 0
  }
  .databox.databox-vertical.databox-lg.databox-inverted .databox-top {
    height: 100px
  }
  .databox.databox-vertical.databox-lg.databox-inverted .databox-bottom {
    height: 50px
  }
  .databox.databox-vertical.databox-lg.databox-halved .databox-icon>.fa:before, .databox.databox-vertical.databox-lg.databox-halved .databox-icon .typcn:before, .databox.databox-vertical.databox-lg.databox-halved .databox-icon .glyphicon:before, .databox.databox-vertical.databox-lg.databox-halved .databox-icon .wi:before {
    font-size: 58px;
    padding: 0
  }
  .databox.databox-vertical.databox-lg.databox-halved .databox-top {
    height: 75px
  }
  .databox.databox-vertical.databox-lg.databox-halved .databox-bottom {
    height: 75px
  }
  .databox.databox-vertical.databox-xlg {
    height: 200px;
    width: 100%
  }
  .databox.databox-vertical.databox-xlg .databox-icon>.fa:before, .databox.databox-vertical.databox-xlg .databox-icon .typcn:before, .databox.databox-vertical.databox-xlg .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
  }
  .databox.databox-vertical.databox-xlg .databox-top {
    height: 50px
  }
  .databox.databox-vertical.databox-xlg .databox-bottom {
    height: 150px
  }
  .databox.databox-vertical.databox-xlg.databox-inverted .databox-icon>.fa:before, .databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .typcn:before, .databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .wi:before {
    font-size: 120px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xlg.databox-inverted .databox-top {
    height: 150px
  }
  .databox.databox-vertical.databox-xlg.databox-inverted .databox-bottom {
    height: 50px
  }
  .databox.databox-vertical.databox-xlg.databox-halved .databox-icon>.fa:before, .databox.databox-vertical.databox-xlg.databox-halved .databox-icon .typcn:before, .databox.databox-vertical.databox-xlg.databox-halved .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xlg.databox-halved .databox-icon .wi:before {
    font-size: 85px;
    padding: 0
  }
  .databox.databox-vertical.databox-xlg.databox-halved .databox-top {
    height: 100px
  }
  .databox.databox-vertical.databox-xlg.databox-halved .databox-bottom {
    height: 100px
  }
  .databox.databox-vertical.databox-xxlg {
    height: 300px;
    width: 100%
  }
  .databox.databox-vertical.databox-xxlg .databox-icon>.fa:before, .databox.databox-vertical.databox-xxlg .databox-icon .typcn:before, .databox.databox-vertical.databox-xxlg .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
  }
  .databox.databox-vertical.databox-xxlg .databox-top {
    height: 50px
  }
  .databox.databox-vertical.databox-xxlg .databox-bottom {
    height: 250px
  }
  .databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon>.fa:before, .databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .typcn:before, .databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .wi:before {
    font-size: 220px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xxlg.databox-inverted .databox-top {
    height: 250px
  }
  .databox.databox-vertical.databox-xxlg.databox-inverted .databox-bottom {
    height: 50px
  }
  .databox.databox-vertical.databox-xxlg.databox-halved .databox-icon>.fa:before, .databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .typcn:before, .databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .wi:before {
    font-size: 120px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xxlg.databox-halved .databox-top {
    height: 150px
  }
  .databox.databox-vertical.databox-xxlg.databox-halved .databox-bottom {
    height: 150px
  }
  .databox.databox-vertical.databox-xxxlg {
    height: 500px;
    width: 100%
  }
  .databox.databox-vertical.databox-xxxlg .databox-icon>.fa:before, .databox.databox-vertical.databox-xxxlg .databox-icon .typcn:before, .databox.databox-vertical.databox-xxxlg .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxxlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
  }
  .databox.databox-vertical.databox-xxxlg .databox-top {
    height: 50px
  }
  .databox.databox-vertical.databox-xxxlg .databox-bottom {
    height: 450px
  }
  .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon>.fa:before, .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .typcn:before, .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .wi:before {
    font-size: 420px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-top {
    height: 450px
  }
  .databox.databox-vertical.databox-xxxlg.databox-inverted .databox-bottom {
    height: 50px
  }
  .databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon>.fa:before, .databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .typcn:before, .databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .glyphicon:before, .databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .wi:before {
    font-size: 220px;
    padding: 10px
  }
  .databox.databox-vertical.databox-xxxlg.databox-halved .databox-top {
    height: 250px
  }
  .databox.databox-vertical.databox-xxxlg.databox-halved .databox-bottom {
    height: 250px
  }
  .databox .chart {
    font-size: 13px
  }

  

  .salary-slip {
    margin: 15px;
  }
  .salary-slip .empDetail {
    width: 100%;
    text-align: left;
    border: 2px solid black;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .salary-slip .head {
    margin: 10px;
    margin-bottom: 50px;
    width: 100%;
  }
  .salary-slip .companyName {
    text-align: right;
    font-size: 25px;
    font-weight: bold;
  }
  .salary-slip .salaryMonth {
    text-align: center;
  }
  .salary-slip .table-border-bottom {
    border-bottom: 1px solid;
  }
  .salary-slip .table-border-right {
    border-right: 1px solid;
  }
  .salary-slip .myBackground {
    padding-top: 10px;
    text-align: left;
    border: 1px solid black;
    height: 40px;
  }
  .salary-slip .myAlign {
    text-align: center;
    border-right: 1px solid black;
  }
  .salary-slip .myTotalBackground {
    padding-top: 10px;
    text-align: left;
    background-color: #ebf1de;
    border-spacing: 0px;
  }
  .salary-slip .align-4 {
    width: 25%;
    float: left;
  }
  .salary-slip .tail {
    margin-top: 35px;
  }
  .salary-slip .align-2 {
    margin-top: 25px;
    width: 50%;
    float: left;
  }
  .salary-slip .border-center {
    text-align: center;
  }
  .salary-slip .border-center th,
  .salary-slip .border-center td {
    border: 1px solid black;
  }
  .salary-slip th,
  .salary-slip td {
    padding-left: 6px;
  }
.myimg{
  width:100%; background-color: #ccc; height: 120px;
  background-image: url('/images/page-img/profile-bg.jpg');
}

.mtop600{
  margin-top: 600px;
}
.gap{
  min-height: 600px;
}

.font40{
  font-size: 40px;
}
.padLRTzero{
  padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.bg-themeprimary {
  background-color: #51445f!important;
}

.bg-white {
  background-color: #e6ebf1de!important;
}

.bg-themesecondary {
  background-color: #fb6e52!important;
}
.bg-themefourthcolor {
  background-color: #a0d468!important;
}

.table thead th {
  
  border-bottom: 2px solid #12171d;
  background: #51445f;
  color: #fff;
}
.badge-pill{
    padding-right: 1.6em;
    padding-left: 1.6em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
}

.tail-datetime-calendar {
    /* width: 245px; */
    width: 945px;
    height: auto;
    /* margin: 15px; */
    margin-left: 10px;
    margin-right: 10px;
    padding: 0;
    display: block;
    overflow: hidden;
    /* position: absolute; */
    border-collapse: separate;
    font-family: "Open Sans", Calibri, Arial, sans-serif;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }
  .tail-datetime-calendar:after {
    clear: both;
    content: "";
    display: block;
    font-size: 0;
    visibility: hidden;
  }
  .tail-datetime-calendar.calendar-static {
    margin-left: auto;
    margin-right: auto;
  }
  .tail-datetime-calendar .calendar-navi {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    /* background-color: #cd283c; */
    border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-bottom: 2px solid #12171d;
    background: #51445f;
  }
  .tail-datetime-calendar .calendar-navi span {
    cursor: pointer;
    color: #fff;
    margin: 0;
    padding: 0;
    display: table-cell;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-position: center center;
  }
  .tail-datetime-calendar .calendar-navi span:first-child,
  .tail-datetime-calendar .calendar-navi span:last-child {
    width: 35px;
    padding: 0 0 5px 0;
    font-size: 22px;
  }
  .tail-datetime-calendar .calendar-navi span:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
  .tail-datetime-calendar .calendar-navi span.button-prev {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
      9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
      TYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+");
  }
  .tail-datetime-calendar .calendar-navi span.button-next {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
      9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
      TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=");
  }
  .tail-datetime-calendar .calendar-navi span.button-check {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
      9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
      SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
  }
  .tail-datetime-calendar .calendar-navi span.button-close {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
      9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
      SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\
      NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
  }
  .tail-datetime-calendar .calendar-date {
    margin: 0;
    padding: 0;
    display: block;
  }
  .tail-datetime-calendar .calendar-date table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
    border-collapse: collapse;
  }
  .tail-datetime-calendar .calendar-date table thead tr > *,
  .tail-datetime-calendar .calendar-date table tbody tr > * {
    color: #ffffff;
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    line-height: 35px;
    border: 0;
    /* background-color: #223344; */
    background-color:#51445f
  }
  .tail-datetime-calendar .calendar-date table tbody tr > * {
    color: #334455;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    background-color: #ffffff;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > * > span {
    color: inherit;
    z-index: 10;
    position: relative;
  }
  /* Select a Day */
  /* .tail-datetime-calendar .calendar-date table tbody tr > *::after {
    top: 18px;
    left: 53px;
    width: 29px;
    height: 29px;
    content: "";
    display: block;
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    -webkit-border-radius: 50%;
  } */
  .tail-datetime-calendar .calendar-date table tbody tr > *.pad {
    padding: 8px;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.pad2 {
    padding-left: 9px !important;
    padding-right: 10px !important;
  }
 
  .tail-datetime-calendar .calendar-date table tbody tr td > *.present_days {
    border-bottom: 3px solid #18b82d;
    background: #18b82d;
    padding: 2px 6px;
    color: #fff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.absent_days {
    border-bottom: 3px solid red;
    background: red;
    padding: 2px 6px;
    color: #fff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.holiday_days {
    border-bottom: 3px solid #0ec9d2;
    background: #0ec9d2;
    padding: 2px 6px;
    color: #fff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.leave_days {
    border-bottom: 3px solid #0000ff;
    background: #0000ff;
    padding: 2px 6px;
    color: #fff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr td > *.leave_days_applied {
    border-bottom: 3px solid #800080;
    background: #800080;
    padding: 2px 6px;
    color: #fff;
  }


  .tail-datetime-calendar .calendar-date table tbody tr > *.today {
    color: #3296c8;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.today:after {
    border-color: #3296c8;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *:hover {
    color: #cd283c;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *:hover:after {
    border-color: #cd283c;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.empty,
  .tail-datetime-calendar .calendar-date table tbody tr > *.disable {
    color: #8899aa;
    cursor: not-allowed;
    background-color: #efefef;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.empty:hover:after,
  .tail-datetime-calendar .calendar-date table tbody tr > *.disable:hover:after {
    content: "";
    display: none;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.current span {
    color: #fff;
  }
  .tail-datetime-calendar .calendar-date table tbody tr > *.current:after {
    border-color: #3296c8;
    background-color: #3296c8;
  }
  /* Select A Month */
  .tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * {
    width: 81.66666666666667px;
    padding: 5px;
    line-height: 25px;
  }
  .tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * span {
    display: block;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }
  .tail-datetime-calendar
    .calendar-date
    table.calendar-month
    tbody
    tr
    > *:hover
    span {
    border-color: #d0d0d0;
    box-shadow: 0 1px 0 0 #efefef;
    -webkit-box-shadow: 0 1px 0 0 #efefef;
  }
  /* Select a Time */
  .tail-datetime-calendar .calendar-time {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    text-align: center;
    background-color: #ffffff;
    border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
  }
  .tail-datetime-calendar .calendar-date + .calendar-time {
    border-top: 1px solid #d0d0d0;
    background-color: #f8f8f8;
  }
  .tail-datetime-calendar .calendar-time .calendar-field {
    width: 33.3333333333%;
    padding: 10px 0;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: top;
  }
  .tail-datetime-calendar .calendar-time .calendar-field:first-child {
    text-align: right;
  }
  .tail-datetime-calendar .calendar-time .calendar-field:first-child:after {
    top: 12px;
    right: -10px;
    content: ":";
    position: absolute;
  }
  .tail-datetime-calendar .calendar-time .calendar-field:last-child {
    text-align: left;
  }
  .tail-datetime-calendar .calendar-time .calendar-field:last-child:after {
    top: 12px;
    left: -10px;
    content: ":";
    position: absolute;
  }
  .tail-datetime-calendar .calendar-time .calendar-field input[type="number"] {
    width: 100%;
    max-width: 50px;
    margin: 0;
    padding: 3px 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }
  .tail-datetime-calendar
    .calendar-time
    .calendar-field
    input[type="number"]:hover {
    border-color: #a0a0a0;
  }
  .tail-datetime-calendar
    .calendar-time
    .calendar-field
    input[type="number"]:focus {
    border-color: #3296c8;
  }
  .tail-datetime-calendar .calendar-time .calendar-field label {
    color: #778899;
    margin: 5px 0 0 0;
    padding: 0;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  /* @end tail.DateTime */
  
  .sqr {
    float: left;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
  }
  
  .precent {
    background: #18b82d;
  }
  
  .holiday {
    background: #0ec9d2;
  }
  
  .weekly_off {
    background: red;
  }
  .leave{
    background: #0000ff;
  }
  .leave_apply{
    background: #800080;
  }
/*just bg and body style*/
.container{
    background-color:#51445f;
    padding-bottom:20px;
    margin-top:10px;
    border-radius:5px;
    /* padding-top: 5px; */
    }
    .center{
    text-align:center;  
    }
    #top{
    margin-top:20px;  
    }
    .btn-container{
    background:#fff;
    border-radius:5px;
    padding-bottom:50px;
    margin-bottom:20px;
    padding-top: 5px;
    }
    .white{
    color:white;
    }
    .imgupload{
    color:#1E2832;
    padding-top:40px;
    font-size:7em;
    }
    #namefile{
    color:black;
    }
    h4>strong{
    color:#ff3f3f
    }
    /* .btn-primary{
    border-color: #ff3f3f !important;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #ff3f3f !important;
    border-color: #ff3f3f !important;
    } */
    
    /*these two are set to not display at start*/
    .imgupload.ok{
    display:none;
    color:green;
    }
    .imgupload.stop{
    display:none;
    color:red;
    }
    
    
    /*this sets the actual file input to overlay our button*/ 
    #fileup{
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    width:200px;
    cursor: pointer;
    position:absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    height: 50px;
    }
    
    /*switch between input and not active input*/
    #submitbtn{
      padding:5px 50px;
      display:none;
    }
    #fakebtn{
      padding:5px 40px;
    }
    
    
    /*www.emilianocostanzo.com*/
    #sign{
        color:#1E2832;
        position:fixed;
        right:10px;
        bottom:10px;
        text-shadow:0px 0px 0px #1E2832;
        transition:all.3s;
    }
    #sign:hover{
        color:#1E2832;
        text-shadow:0px 0px 5px #1E2832;
    }

    /* .title {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #999;
        text-align: center;
    }
    
    h1 {
        font-size: 16px;
        font-weight: 300;
        color: #666;
    }
     */
    .dropzone {
        /* width: 100px;
        height: 80px; */
        border: 1px dashed #999;
        border-radius: 3px;
        text-align: center;
        margin: 30px;
    padding-top: 10px;
    }
    
    .upload-icon {
        margin: 25px 2px 2px 2px;
    }
    
    .upload-input {
        position: relative;
        top: -62px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .reactEasyCrop_Container{
        margin: 0px 7px 0px 20px;
        border-radius: 6%;
    }

    .homecmp-container .webcam-container
    {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding:10px 0;
    }
    .homecmp-container .webcam-container .webcam-btn
    {
    margin-top: 10px;
    width:30%;
    }
.react-datepicker-wrapper{
    display: unset !important;
}

.leave_div .row{
    margin-right: -23px;
    margin-left: -23px;
}

.list-group-item {
    border:unset !important;
}
.ul{
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
.holiday .iq-card .iq-card-header {
    min-height: 75px !important;
}

.list-group-item {
    background-color:#ccc; margin-right: 3px; height: 35px;  color: #fff; padding:3px 8px 7px 8px; margin-top: 10px; 
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;  
}
.ulbg{
 background-color: #212529;
}
.list-group-item a{
    padding-top: 1px; color: #fff; 
}

.tbl{
    display: inline-table !important;
}
.reactEasyCrop_Container{
    position: relative !important;
}
.reactEasyCrop_Container > img{
    position: unset !important;
}

.homecmp-container .webcam-container
{
display: flex;
flex-direction: column;
text-align: center;
padding:10px 0;
}
.homecmp-container .webcam-container .webcam-btn
{
margin-top: 10px;
width:30%;
}
